import React from "react"
import { Link as LinkGatsby } from "gatsby"
import {
  Heading,
  Text,
  Flex,
  Box,
  Img,
  Image,
  Container,
  Button,
  Link,
} from "@chakra-ui/react"
import { H, Section } from "../componentsWithChakra"
import {
  i004_checklists,
  i005_path,
  i006_colleagues,
  i007_conference,
  i009_decisionMaking,
  i010_diagram,
  i012_event,
  i013_accounting,
  i016_infoGraphic,
  i020_target,
  i021_memo,
  i023_presentation,
  i024_process,
  i026_workflow,
  i032_tactic,
  i034_workflow,
} from "../img/svg/planning"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import pcoLaptop from "../img/0800/pcoLaptop.png"
import { META_DESCRIPTION } from "../const/meta.js"
import { META_APP_SITE } from "../const/urlGatsby.js"

const PageIndex = () => (
  <Layout>
    <SEO
      title="THE PROJECT STATUS | Otherwise you may fly blind."
      urlPage="/"
    />

    <Section maxW="1200px" mx="auto" px={4} pb={16} pt={16}>
      {/* <Text fontSize="24px" color="red.500" textAlign="center" mb={8}>
        Support your project control with an intelligent tool, that beats any
        spreadsheet solution with automatic evaluation and aggregation, and with
        a lot of reliability and efficiency for high quality reporting.
      </Text> */}

      <Flex align="flex-start" wrap="wrap" mb={"60px"} px={4}>
        <Flex
          w="30%"
          direction="column"
          align="center"
          mt={["100px", "50px", "100px", "176px"]}
          display={["none", "none", "flex"]}
        >
          <Text textAlign="center" mb={2}>
            KANBAN, SCRUM & WATERFALL
          </Text>
          <Button
            as={Link}
            fontSize={["md", "md", "lg", "xl"]}
            color="white"
            bg="blue.500"
            p={4}
            h="auto"
            mb={2}
            borderRadius="8px"
            href={META_APP_SITE + "/"}
            isExternal
            _hover={{ color: "white", bg: "blue.700" }}
          >
            START NOW — IT'S FREE
          </Button>
          <Text textAlign="center">No login required</Text>
        </Flex>
        <Flex w={["100%", "100%", "70%"]} align="center" direction="column">
          <Image src={pcoLaptop} htmlWidth={800} htmlHeight={467}></Image>
          <Box as="h2" fontSize="24px" textAlign="center">
            Never again lose management oversight over your important projects.
          </Box>
          <Text fontSize="20px" textAlign="center" px={8}>
            Useful for agile and classical (waterfall) project management
            methodologies.
          </Text>
        </Flex>
      </Flex>

      <Box as="h3" textStyle="h3" mt={8} mb={8}>
        Based on Relevant Experience, the Latest Knowledge and Organizational
        Research
      </Box>
      <Flex wrap="wrap">
        <Flex
          w={["50%", "50%", "25%"]}
          px={2}
          direction="column"
          align="center"
          mb={8}
        >
          <Img src={i023_presentation} w={["50%"]} />
          <Text textAlign="center" mt={4} px={2}>
            Based on the{" "}
            <Link as={LinkGatsby} to="/concepts/essence-project-management/">
              <strong>»Essence of Project Management«</strong>
            </Link>
            .
          </Text>
        </Flex>
        <Flex
          w={["50%", "50%", "25%"]}
          px={2}
          direction="column"
          align="center"
          mb={8}
        >
          <Img src={i021_memo} w={["50%"]} position="relative" top="-6px" />
          <Text textAlign="center" mt={4} px={2}>
            Respecting the{" "}
            <Link
              as={LinkGatsby}
              to="/concepts/the-10-commandments-of-project-control/"
            >
              <strong>10&nbsp;Commandments of Project Control</strong>.
            </Link>
          </Text>
        </Flex>
        <Flex
          w={["50%", "50%", "25%"]}
          px={2}
          direction="column"
          align="center"
          mb={8}
        >
          <Img src={i004_checklists} w={["50%"]} />
          <Text textAlign="center" mt={4} px={2}>
            Implementing the <strong>Golden Rule</strong> of project management:
            always focus on output.
          </Text>
        </Flex>
        <Flex
          w={["50%", "50%", "25%"]}
          px={2}
          direction="column"
          align="center"
          mb={8}
        >
          <Img src={i016_infoGraphic} w={["50%"]} />
          <Text textAlign="center" mt={4} px={2}>
            Allowing for immutability, backward{" "}
            <strong>review and graphical analysis</strong>.
          </Text>
        </Flex>
      </Flex>
    </Section>

    <Container maxW="100%" bg="grey.100">
      <Section maxW="1200px" mx="auto" px={4} py={16}>
        <Box as="h3" textStyle="h3" mb={8}>
          High Degree of Innovation
        </Box>
        <Flex wrap="wrap">
          <Flex
            w={["50%", "50%", "25%"]}
            px={2}
            direction="column"
            align="center"
            mb={8}
          >
            <Img src={i024_process} w={["50%"]} />
            <Text textAlign="center" mt={4} px={2}>
              <strong>Status travel</strong> for regular status dates and the
              project development.
            </Text>
          </Flex>
          <Flex
            w={["50%", "50%", "25%"]}
            px={2}
            direction="column"
            align="center"
            mb={8}
          >
            <Img src={i010_diagram} w={["50%"]} />
            <Text textAlign="center" mt={4} px={2}>
              <strong>Vertical aggregation</strong> for linking executive with
              operational perspectives.
            </Text>
          </Flex>
          <Flex
            w={["50%", "50%", "25%"]}
            px={2}
            direction="column"
            align="center"
            mb={8}
          >
            <Img src={i012_event} w={["50%"]} />
            <Text textAlign="center" mt={4} px={2}>
              <strong>Better milestone planning</strong> with pinning deadlines
              of critical dates.
            </Text>
          </Flex>
          <Flex
            w={["50%", "50%", "25%"]}
            px={2}
            direction="column"
            align="center"
            mb={8}
          >
            <Img src={i020_target} w={["50%"]} />
            <Text textAlign="center" mt={4} px={2}>
              <strong>Forecasting and early collection</strong> of information
              on issues and to be missed deadlines.
            </Text>
          </Flex>
        </Flex>
      </Section>
    </Container>

    <Section maxW="1200px" mx="auto" px={4} py={16}>
      <Box as="h3" textStyle="h3" mb={8}>
        Achieving Extra Transparency
      </Box>
      <Flex wrap="wrap">
        <Flex
          w={["50%", "50%", "25%"]}
          px={2}
          direction="column"
          align="center"
          mb={8}
        >
          <Img src={i013_accounting} w={["50%"]} />
          <Text textAlign="center" mt={4} px={2}>
            Overview of all <strong>relevant ratios</strong> for review or for
            multi project management.
          </Text>
        </Flex>
        <Flex
          w={["50%", "50%", "25%"]}
          px={2}
          direction="column"
          align="center"
          mb={8}
        >
          <Img src={i007_conference} w={["50%"]} />
          <Text textAlign="center" mt={4} px={2}>
            Managing <strong>all people involved</strong> on one page as a basis
            for team building, transparency and communication.
          </Text>
        </Flex>
        <Flex
          w={["50%", "50%", "25%"]}
          px={2}
          direction="column"
          align="center"
          mb={8}
        >
          <Img src={i009_decisionMaking} w={["50%"]} />
          <Text textAlign="center" mt={4} px={2}>
            Automatic <strong>coloring of traffic lights</strong> for supporting
            controllers and avoiding green washing.
          </Text>
        </Flex>
        <Flex
          w={["50%", "50%", "25%"]}
          px={2}
          direction="column"
          align="center"
          mb={8}
        >
          <Img src={i005_path} w={["50%"]} />
          <Text textAlign="center" mt={4} px={2}>
            <strong>Interlinked algorithmic trees</strong> for horizontal
            planning and vertical aggregations and restrictions.
          </Text>
        </Flex>
      </Flex>
    </Section>

    <Container maxW="100%" bg="grey.100">
      <Section maxW="1200px" mx="auto" px={4} py={16}>
        <Box as="h3" textStyle="h3" mb={8}>
          Further Relevant Topics
        </Box>
        <Flex wrap="wrap">
          <Flex
            w={["50%", "50%", "25%"]}
            px={2}
            direction="column"
            align="center"
            mb={8}
          >
            <Img src={i026_workflow} w={["50%"]} />
            <Text textAlign="center" mt={4} px={2}>
              All the <strong>security</strong> for your critical projects
              because your data stay local on your hard drive.
            </Text>
          </Flex>
          <Flex
            w={["50%", "50%", "25%"]}
            px={2}
            direction="column"
            align="center"
            mb={8}
          >
            <Img src={i032_tactic} w={["50%"]} />
            <Text textAlign="center" mt={4} px={2}>
              <strong>Download and upload</strong> your project plan for
              collaboration and exchange.
            </Text>
          </Flex>
          <Flex
            w={["50%", "50%", "25%"]}
            px={2}
            direction="column"
            align="center"
            mb={8}
          >
            <Img src={i034_workflow} w={["50%"]} />
            <Text textAlign="center" mt={4} px={2}>
              <strong>Beating spreadsheets</strong> in comfort with convenient
              handling, automations and reliability.
            </Text>
          </Flex>
          <Flex
            w={["50%", "50%", "25%"]}
            px={2}
            direction="column"
            align="center"
            mb={8}
          >
            <Img src={i006_colleagues} w={["50%"]} />
            <Text textAlign="center" mt={4} px={2}>
              <strong>Business School evaluated</strong> by researching and
              testing organizational content academically.
            </Text>
          </Flex>
        </Flex>
      </Section>
    </Container>
  </Layout>
)

export default PageIndex
